import { BsXLg } from "react-icons/bs";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Spinner,
} from "reactstrap";

const ModalMarkersMission = ({
  toggle,
  setPoles,
  setTrees,
  setCableSpacers,
  missions,
  loading,
  selectedMissions,
  setSelectedMissions,
}) => {
  const handleMissionChange = (missionId) => {
    setSelectedMissions((prevSelected) =>
      prevSelected.includes(missionId)
        ? prevSelected.filter((id) => id !== missionId)
        : [...prevSelected, missionId]
    );
  };

  const updateMarkersVisibility = (missionId) => {
    const mission = missions.find((m) => m.missionId === missionId);
    if (!mission) return;

    const isMissionSelected = selectedMissions.includes(missionId);

    const updateVisibility = (items, missionItems) =>
      items.map((item) => {
        if (missionItems.some((missionItem) => missionItem.id === item.id)) {
          return { ...item, enabled: !isMissionSelected };
        }
        return item;
      });

    setPoles((prevPoles) => updateVisibility(prevPoles, mission.poles));
    setTrees((prevTrees) => updateVisibility(prevTrees, mission.trees));
    setCableSpacers((prevSpacers) =>
      updateVisibility(prevSpacers, mission.cableSpacers)
    );
  };

  return (
    <>
      <Card
        className="modal-pole-components-card"
        style={{
          left: "120px",
          top: "65px",
          position: "absolute",
          zIndex: "1030",
          display: "block",
          overflowX: "scroll",
          maxHeight: "85%",
          maxWidth: "50%",
        }}
      >
        <CardTitle
          style={{
            position: "sticky",
            top: 0,
            zIndex: "2000",
            backgroundColor: "white",
            margin: "0",
          }}
        >
          <div className="d-flex align-items-center p-3 gap-1">
            <Col style={{ fontSize: "1.2em" }}>Filtro de Missões</Col>
            <BsXLg onClick={toggle} style={{ cursor: "pointer" }} />
          </div>
          <hr className="m-0" />
        </CardTitle>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!loading &&
              missions?.map(({ missionId, name }) => (
                <div key={missionId} className="form-check">
                  <Input
                    type="checkbox"
                    id={`mission-${missionId}`}
                    name={name}
                    value={missionId}
                    checked={selectedMissions?.includes(missionId)}
                    onChange={() => {
                      handleMissionChange(missionId);
                      updateMarkersVisibility(missionId);
                    }}
                  />
                  <Label
                    for={`mission-${missionId}`}
                    className="form-check-label"
                  >
                    {name}
                  </Label>
                </div>
              ))}

            {loading && (
              <div className="m-auto text-center">
                <Spinner
                  color="primary"
                  style={{
                    margin: "auto",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  Loading...
                </Spinner>
              </div>
            )}

            {!loading && missions.length === 0 && (
              <div className="m-auto text-center">
                <span>Missões não encontradas!</span>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ModalMarkersMission;
