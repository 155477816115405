import React, { useEffect } from "react";
import {
  BsCardImage,
  BsCheck,
  BsFillExclamationTriangleFill,
  BsTrash,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const MissionViewImages = ({
  listImagesMode,
  listImages,
  projectData,
  missionDetails,
  listDeleteImages,
  setListDeleteImages,
  setCount,
  openModalDeleteContent,
  openModalDetalhes,
  setListImagesToView,
  setListImagesContent,
  listaImagensCarregada,
  count,
  pagePaginationAPI,
  listImagesToView,
}) => {
  function doisDigitos(number) {
    var output = number + "";
    while (output.length < 2) {
      output = "0" + output;
    }
    return output;
  }

  function formatData(element) {
    if (element) {
      let dia =
        doisDigitos(element[2]) +
        "/" +
        doisDigitos(element[1]) +
        "/" +
        element[0];
      return dia;
    }
  }

  useEffect(() => {
    let auxList = [];
    if (listImagesMode === "list") {
      auxList.push(
        <Col className="m-0" xs={12} key={Math.random()}>
          <Row className="p-0 align-items-center">
            {/* Conditionally render the spacer column */}
            {projectData?.status !== "Finalizado" &&
              missionDetails?.status !== "Processamento finalizado" && (
                <Col
                  className="d-none d-md-block"
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                ></Col>
              )}

            {/* Columns for displaying data */}
            <Col xs={1} sm={1} md={1} className="m-auto me-2"></Col>

            <Col className="m-auto col p-0">
              <Row className="col-12 m-0 p-0">
                <Col
                  xs={12}
                  sm={8}
                  md={5}
                  lg={4}
                  className="ms-0 me-0 mt-auto mb-auto row p-0"
                >
                  <small>
                    <strong>Nome</strong>
                  </small>
                </Col>
                <Col
                  sm={3}
                  md={2}
                  className="ms-0 me-0 mt-auto mb-auto row p-0"
                >
                  <small>
                    <strong>Captura</strong>
                  </small>
                </Col>
                <Col
                  sm={3}
                  md={2}
                  className="ms-0 me-0 mt-auto mb-auto row p-0"
                >
                  <small>
                    <strong>Upload</strong>
                  </small>
                </Col>
                <Col
                  md={4}
                  lg={3}
                  className="ms-0 me-0 mt-auto mb-auto row p-0"
                >
                  <small>
                    <strong>Latitude/Longitude</strong>
                  </small>
                </Col>

                {/* Conditionally render the delete column */}
                {projectData?.status !== "Finalizado" &&
                  missionDetails?.status !== "Processamento finalizado" && (
                    <Col
                      sm={2}
                      md={1}
                      lg={1}
                      className="m-0 mt-auto mb-auto text-center d-none d-sm-block"
                    >
                      <small>
                        <strong>Excluir</strong>
                      </small>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
        </Col>
      );
    }
    if (listImages)
      listImages.forEach((el) => {
        if (listImagesMode === "block") {
          auxList.push(
            <Col
              key={Math.random()}
              className="col-12"
              sm={6}
              md={4}
              lg={3}
              style={{
                padding: ".5em",
              }}
            >
              <Card>
                <CardBody
                  style={{
                    padding: "0",
                  }}
                >
                  <CardTitle
                    tag="h5"
                    style={{
                      padding: "0.5em",
                    }}
                  >
                    <Row>
                      <Col className="mt-auto mb-auto ps-1 pe-1">
                        {/* #{el.id} */}
                        <Row className="m-0 p-0">
                          {projectData && missionDetails ? (
                            <Col className="col-1 mt-auto mb-auto">
                              <div
                                className="p-0 m-0"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  border: "1px solid #CCC",
                                  borderRadius: "6px",
                                }}
                                onClick={() => {
                                  let aux = listDeleteImages;
                                  setListDeleteImages(null);
                                  if (
                                    listDeleteImages.filter(
                                      (item) => item === el.id
                                    ).length > 0
                                  ) {
                                    aux = listDeleteImages.filter(
                                      (item) => item !== el.id
                                    );
                                  } else {
                                    aux.push(el.id);
                                  }
                                  setCount(Math.random());
                                  setListDeleteImages(aux);
                                }}
                              >
                                {listDeleteImages
                                  ? listDeleteImages.map((item) => {
                                      let returnItem = null;
                                      if (item === el.id)
                                        returnItem = (
                                          <BsCheck
                                            key={Math.random()}
                                            className=""
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              marginTop: "-10px",
                                            }}
                                          />
                                        );
                                      return returnItem;
                                    })
                                  : ""}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col
                            className="ms-3 me-0 ms-1 p-0"
                            onClick={() => {
                              let aux = listDeleteImages;
                              setListDeleteImages(null);
                              if (
                                listDeleteImages.filter(
                                  (item) => item === el.id
                                ).length > 0
                              ) {
                                aux = listDeleteImages.filter(
                                  (item) => item !== el.id
                                );
                              } else {
                                aux.push(el.id);
                              }
                              setCount(Math.random());
                              setListDeleteImages(aux);
                            }}
                          >
                            <small
                              className="text-start"
                              style={{ fontSize: "0.6em" }}
                            >
                              {decodeURI(
                                el.path.split("/")[
                                  el.path.split("/").length - 1
                                ]
                              )}
                            </small>
                          </Col>
                        </Row>
                      </Col>
                      {projectData &&
                      projectData.status &&
                      projectData.status !== "Finalizado" &&
                      missionDetails &&
                      missionDetails.status &&
                      missionDetails.status !== "Processamento finalizado" ? (
                        <>
                          <Link
                            className="mt-auto mb-auto mr-5 btn btn-item-list p-0"
                            type="button"
                            style={{
                              height: "28px",
                              width: "28px",
                            }}
                            onClick={() => {
                              openModalDeleteContent(
                                el.id,
                                <BsFillExclamationTriangleFill />,
                                "Imagem #" + el.id,
                                "Deseja realmente apagar a imagem #" +
                                  el.id +
                                  "?",
                                true
                              );
                            }}
                          >
                            <BsTrash />
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </CardTitle>
                  <Row>
                    <img
                      alt="loaded img"
                      onClick={() => openModalDetalhes(el)}
                      style={{ width: "100%", cursor: "pointer" }}
                      src={el.path_low}
                    />
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <small>
                        {el.capture_date
                          ? "Capturada em: " + formatData(el.capture_date)
                          : ""}
                      </small>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          );
        } else {
          auxList.push(
            <Col
              className="border rounded pt-1 pb-1 pr-4 pl-4"
              style={{ cursor: "pointer" }}
              sm={12}
              key={Math.random()}
            >
              <Row className="m-0">
                <Col xs={1} sm={1} md={1} className="m-auto me-2">
                  <div
                    className="p-0 m-0"
                    style={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid #CCC",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      let aux = listDeleteImages;
                      setListDeleteImages(null);
                      if (
                        listDeleteImages.filter((item) => item === el.id)
                          .length > 0
                      ) {
                        aux = listDeleteImages.filter((item) => item !== el.id);
                      } else {
                        aux.push(el.id);
                      }
                      setCount(Math.random());
                      setListDeleteImages(aux);
                    }}
                  >
                    {listDeleteImages
                      ? listDeleteImages.map((item) => {
                          let returnItem = null;
                          if (item === el.id)
                            returnItem = (
                              <BsCheck
                                key={Math.random()}
                                className=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  marginTop: "-10px",
                                }}
                              />
                            );
                          return returnItem;
                        })
                      : ""}
                  </div>
                </Col>

                <Col className="m-auto col p-0">
                  <Row
                    className="col-12 m-0 p-0"
                    onClick={() => openModalDetalhes(el)}
                  >
                    <Col
                      xs={12}
                      sm={8}
                      md={5}
                      lg={4}
                      className="ms-0 me-0 mt-auto mb-auto row p-0"
                    >
                      <Col className="col-2">
                        <BsCardImage className="ms-0 mt-auto mb-auto me-3 p-0" />
                      </Col>
                      <Col className="col">
                        <small>
                          {decodeURI(
                            el.path.split("/")[el.path.split("/").length - 1]
                          )}
                        </small>
                      </Col>
                    </Col>
                    <Col
                      sm={3}
                      md={2}
                      className="mt-auto mb-auto text-center d-none d-sm-block"
                    >
                      <small>{formatData(el.capture_date)}</small>
                    </Col>
                    <Col
                      sm={3}
                      md={2}
                      className="mt-auto mb-auto text-center d-none d-md-none d-lg-block"
                    >
                      <small>{formatData(el.upload_date)}</small>
                    </Col>
                    <Col
                      md={4}
                      lg={3}
                      className="mt-auto mb-auto text-center d-none d-sm-none d-md-block"
                    >
                      <small>
                        <div>
                          {el.latitude.toString().substring(0, 10)},
                          {el.longitude.toString().substring(0, 10)}
                        </div>
                      </small>
                    </Col>
                  </Row>
                </Col>

                <Col xs={2} sm={2} md={1} className="text-center">
                  {projectData &&
                  projectData.status &&
                  projectData.status !== "Finalizado" &&
                  missionDetails &&
                  missionDetails.status &&
                  missionDetails.status !== "Processamento finalizado" ? (
                    <Link
                      className="mt-auto mb-auto mr-5 btn btn-item-list p-0"
                      type="button"
                      style={{
                        height: "28px",
                        width: "28px",
                      }}
                      onClick={() => {
                        openModalDeleteContent(
                          el.id,
                          <BsFillExclamationTriangleFill />,
                          "Imagem #" + el.id,
                          "Deseja realmente apagar a imagem #" + el.id + "?",
                          true
                        );
                      }}
                    >
                      <BsTrash />
                    </Link>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Col>
          );
        }
      });
    setListImagesContent(auxList);

    setListImagesToView(null);

    let aux = [];
    if (auxList && auxList.length > 0) {
      auxList.filter((item) => {
        aux.push(item);
        return true;
      });
    } else if (!listaImagensCarregada) {
      aux.push(<div key={Math.random()}>Carregando...</div>);
    } else {
      aux.push(<div key={Math.random()}>Sem imagens disponíveis.</div>);
    }
    setListImagesToView(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listImages,
    listImagesMode,
    count,
    pagePaginationAPI,
  ]);

  return listImagesToView;
};

export default MissionViewImages;
