import { useEffect } from "react";
import { useMap } from "react-leaflet";

export const RecenterAutomatically = ({ centerTag, centerAuto, lat, lng }) => {
  let diffLat = centerTag.latMax - centerTag.latMin;
  if (diffLat < 0) diffLat *= -1;
  const map = useMap();
  useEffect(() => {
    if (centerAuto) {
      let diffLat = centerTag.latMax - centerTag.latMin;

      if (diffLat < 0) diffLat *= -1;

      let zoomLevel = 17;

      if (diffLat * 10000 < 10) zoomLevel = 19;
      else if (diffLat * 10000 < 70) zoomLevel = 17;
      else if (diffLat * 10000 < 150) zoomLevel = 16;
      else if (diffLat * 10000 < 300) zoomLevel = 15;
      else if (diffLat * 10000 < 500) zoomLevel = 14;
      else if (diffLat * 10000 < 1000) zoomLevel = 13;
      else if (diffLat * 10000 < 8000) zoomLevel = 11;
      else if (diffLat * 10000 < 10000) zoomLevel = 8;
      else if (diffLat * 10000 < 35000) zoomLevel = 6;
      else zoomLevel = 2;

      let newLat = lat;
      let newLng = lng;
      if (lat === 0 || lng === 0) {
        // Lat e Lng do espírito santo
        newLat = -19.54708428614826;
        newLng = -40.513774803548415;
        zoomLevel = 7;
      }
      map.setView([newLat, newLng], zoomLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);
  
  return null;
};
