import Leaflet from "leaflet";

const SelectedIcon = ({
  url,
  iconAnchor = [22, 62],
  popupAnchor = [10, -44],
  iconSize = [44, 72],
}) => {
  return new Leaflet.Icon({
    iconUrl: url,
    iconAnchor: iconAnchor,
    popupAnchor: popupAnchor,
    iconSize: iconSize,
  });
};

export default SelectedIcon;
