import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Label,
  Row,
  Col,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import {
  BsFillPinMapFill,
  BsChevronDoubleRight,
  BsDownload,
  BsFillCaretRightFill,
  BsChevronDoubleLeft,
  BsXLg,
} from "react-icons/bs";

import "bootstrap/dist/css/bootstrap.min.css";
import "../elements/Styles.css";
import GetTokenApi from "../pages/KeyCloak/GetTokenApi";
import { useEffect } from "react";
var hash = require("object-hash");

const SideBarMap = (props) => {
  const [collapseOpen, setCollapseOpen] = useState({});

  const [keepMenuOpen, setKeepMenuOpen] = useState(false);
  const [userPermission, setUserPermission] = useState(null);

  const [secondaryMenuContentOpen, setSecondaryMenuContentOpen] =
    useState(null);
  const [secondaryMenuContent, setSecondaryMenuContent] = useState(null);
  const [content, setContent] = useState(null);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [orientationMode, setOrientationMode] = useState(null);
  const [forceHideMenu, setForceHideMenu] = useState(null);
  const [rightMenu, setRightMenu] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (props.showLeftMenu) {
      if (props.showLeftMenu === "show") {
        setForceHideMenu(true);
      } else {
        setForceHideMenu(false);
      }
    }
  }, [props]);

  useEffect(() => {
    if (windowSize !== [window.innerWidth, window.innerHeight]) {
      setWindowSize(null);
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth, window.innerHeight]);

  // window.addEventListener("resize", handleResize);

  useEffect(() => {}, [orientationMode]);

  useEffect(() => {
    // handleResize();
    if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] <= windowSize[1]
    ) {
      setOrientationMode("portrait");
      setRightMenu(true);
    } else if (
      windowSize &&
      windowSize[0] &&
      windowSize[1] &&
      windowSize[0] > windowSize[1]
    ) {
      setOrientationMode("landscape");
      setRightMenu(false);
    } else {
      setOrientationMode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  if (!userPermission)
    GetTokenApi().then((res) => {
      setUserPermission(res);
    });

  useEffect(() => {}, [props.loading]);

  useEffect(() => {
    Object.entries(collapseOpen).filter((i) => i[1] === true).length > 0
      ? setKeepMenuOpen(true)
      : setKeepMenuOpen(false);
  }, [collapseOpen]);

  useEffect(() => {
    if (!secondaryMenuContentOpen) {
      setSecondaryMenuContent(null);
    }
    let auxContent = [];
    Object.entries(props).forEach((propsItem) => {
      if (propsItem[0] === "baseMaps") {
        propsItem[1].forEach((e) => {
          let idElement;
          if (e[0]) idElement = hash(e[0]);
          else hash(e);

          if (secondaryMenuContentOpen === e[0]) {
            let auxSecondaryMenuContent = [];
            auxSecondaryMenuContent.push(
              <div key={Math.random()}>
                <h5 className="row m-0 p-0">
                  <div className="col">{e[0]}</div>
                  <div className="col-2">
                    <small>
                      <Link
                        to={false}
                        className="ms-auto"
                        onClick={() => {
                          setSecondaryMenuContentOpen(null);
                        }}
                      >
                        <BsXLg />
                      </Link>
                    </small>
                  </div>
                </h5>
                <hr />
              </div>
            );

            auxSecondaryMenuContent.push(
              <div key={Math.random()} className="ps-4 pe-3">
                {Object.entries(e[1]).map((ek) => {
                  let idSup = Math.floor(Math.random() * 10000);

                  return (
                    <Row key={Math.random()} className="mt-0 mb-0 p-1">
                      <Col className="col-2 m-0 p-0">
                        <Input
                          id={"radioSelectBasemap" + idElement + idSup}
                          name={"radioSelectBasemap" + idElement}
                          addon
                          type="radio"
                          className="m-auto"
                          defaultChecked={
                            e[2].type === ek[1].type ? true : false
                          }
                          onClick={() => e[3](e[1][ek[0]])}
                        />
                      </Col>
                      <Col className="m-0 p-0">
                        <Label
                          className="m-auto"
                          for={"radioSelectBasemap" + idElement + idSup}
                        >
                          {ek[1].label}
                        </Label>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            );
            setSecondaryMenuContent(auxSecondaryMenuContent);
          }

          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (secondaryMenuContentOpen === e[0]) {
                      setSecondaryMenuContentOpen(null);
                    } else {
                      setSecondaryMenuContentOpen(e[0]);
                    }
                  }}
                >
                  <h3
                    className="text-black-50 m-0 text-center icon-menu"
                    id="base-map-tool-tip"
                  >
                    {e[4]}
                  </h3>
                  <UncontrolledTooltip
                    placement="left"
                    target="base-map-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "areasOfInterest") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="area-of-interest-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="area-of-interest-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "enablePoints") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="enable-points-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="enable-points-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "enableTreePoints") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="enable-trees-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="enable-trees-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "enableCableSpacerPointsView") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="enable-cable-spacers-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="enable-cable-spacers-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "newPointPreviewMap") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="new-point-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="new-point-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "mapCarPath") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="car-path-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="car-path-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      } else if (propsItem[0] === "moveMapPoints") {
        propsItem[1].forEach((e) => {
          auxContent.push(
            <div key={Math.random()}>
              <div className="m-0 mb-auto item-menu" key={Math.random()}>
                <div
                  onClick={() => {
                    if (e[2]) e[3](false);
                    else e[3](true);
                  }}
                >
                  <h3 className="text-black-50 m-0 text-center icon-menu" id="move-map-points-tool-tip">
                    {e[2] ? e[4] : e[5]}
                  </h3>

                  <UncontrolledTooltip
                    placement="left"
                    target="move-map-points-tool-tip"
                  >
                    {e[0]}
                  </UncontrolledTooltip>
                </div>

                <BsFillCaretRightFill
                  style={{ fontSize: "0.6em" }}
                  className="text-secondary mt-auto mb-auto"
                />
              </div>
              <hr />
            </div>
          );
        });
      }
    });
    setContent(auxContent);
  }, [props, secondaryMenuContentOpen]);

  return (
    <>
      {rightMenu &&
      orientationMode &&
      orientationMode === "portrait" &&
      !secondaryMenuContentOpen ? (
        <Link
          className={
            "row m-0 p-0 right-menu-arrow-control" +
            (rightMenuOpen ? " right-menu-arrow-control-open" : "")
          }
          style={{ zIndex: "1050" }}
          onClick={() => {
            if (rightMenuOpen) {
              setRightMenuOpen(false);
            } else {
              setRightMenuOpen(true);
            }
          }}
        >
          <h3 className="mt-auto me-0 ms-0 p-0 ps-3">
            {rightMenuOpen ? <BsChevronDoubleRight /> : <BsChevronDoubleLeft />}
          </h3>
        </Link>
      ) : (
        ""
      )}
      <div
        id="barraLateral"
        className={
          "menu-lateral-right text-center m-0 pt-4 p-1" +
          (keepMenuOpen ? " menu-lateral-keep-open" : "") +
          (orientationMode && orientationMode === "portrait"
            ? " menu-right-portrait"
            : "") +
          (rightMenuOpen ? " menu-lateral-right-portrait-open" : "")
        }
        style={{
          ...(windowSize[0] > 767 && props.offCanvas && { right: "410px" }),
        }}
      >
        <Row className="m-0 p-0" key={Math.random()}>
          <Col className="m-0 p-0" key={Math.random()}>
            {content && content}
          </Col>
          {Object.entries(collapseOpen).filter((i) => i[1] === true).length >
          0 ? (
            <Col className="row m-0 p-0" sm={1} key={Math.random()}>
              <div className="m-auto p-2">
                <Link
                  to={false}
                  className="m-auto"
                  onClick={(obj) => {
                    obj.preventDefault();
                    let aux = collapseOpen;
                    setCollapseOpen(false);
                    Object.entries(aux).forEach((el) => {
                      aux[el[0]] = false;
                    });
                    setTimeout(() => {
                      setCollapseOpen(aux);
                    }, 300);
                  }}
                >
                  <BsChevronDoubleRight />
                </Link>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
      {secondaryMenuContent &&
      props.showLeftMenu &&
      props.showLeftMenu !== "show" ? (
        <div
          key={Math.random()}
          className="m-0 secondary-menu-right"
          style={
            orientationMode && orientationMode === "portrait"
              ? { right: "15px", zIndex: "1060" }
              : { maxWidth: "300px", zIndex: "1060" }
          }
        >
          <div>
            <Row className="m-0 p-3">
              {secondaryMenuContent}
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default SideBarMap;
