import Leaflet from "leaflet";

const defaultRadiusMarker = process.env.REACT_APP_DEFAULT_POINT_RADIUS;

export const CreateCustomIcon = (color) => {
  const customSvgIcon = `
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="15" stroke="${color}" stroke-width="0" fill="transparent" />
      <circle cx="20" cy="20" r="${defaultRadiusMarker}" fill="${color}" />
    </svg>
  `;

  return Leaflet.divIcon({
    html: customSvgIcon,
    className: "custom-icon",
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  });
};
