import { useEffect } from "react";
import L from "leaflet";
import "leaflet-polylinedecorator";
import { useMap } from "react-leaflet";

const PolylineDecorator = ({ positions }) => {
  const map = useMap();

  useEffect(() => {
    const polyline = L.polyline(positions).addTo(map);

    const decorator = L.polylineDecorator(polyline, {
      // defines a pattern of 10px-wide arrow-heads, repeated every 90px on the line
      patterns: [
        {
          offset: 0, // Start at the beginning
          repeat: 90, // Add an arrow every 90px
          symbol: L.Symbol.arrowHead({
            pixelSize: 10, // Arrow size
            polygon: false, // Not a filled arrow
            pathOptions: { stroke: true, color: "blue" }, // Arrow style
          }),
        },
      ],
    }).addTo(map);

    return () => {
      map.removeLayer(polyline);
      map.removeLayer(decorator);
    };
  }, [map, positions]);

  return null;
};

export default PolylineDecorator;
