import React from "react";
import { Marker } from "react-leaflet";
import { CreateCustomIcon } from "../CreateCustomIcon/CreateCustomIcon";

const DraggableMapMarker = ({
  point,
  icon,
  color,
  clickFunction,
  onDragStart,
  onDragEnd,
  onDrag,
  markerRef
}) => {
  if (point.draggable === null || point.draggable === undefined) {
    point.draggable = false;
  }

  return (
    <Marker
      draggable={point.draggable}
      icon={point.radius ? icon : CreateCustomIcon(point.color || color)}
      position={[point.latitude, point.longitude]}
      eventHandlers={{
        dragstart: () => onDragStart(point),
        drag: (e) => onDrag(e, point),
        dragend: () => onDragEnd(point),
        click: () => clickFunction(point.draggable),
      }}
      ref={(el) => {
        if (el) {
          markerRef.current[point.id] = el;
        }
      }}
    />
  );
};

export default DraggableMapMarker;
