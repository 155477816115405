import React from 'react';
import MenuPointIcon from '../../assets/icons/3_PONTOS.svg';

const MenuPointIconButton = ({ label, width = "20px", height = "20px", backgroundColor = "transparent" }) => {
  return (
    <button
      aria-label={label}
      style={{
        backgroundColor: backgroundColor, 
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={MenuPointIcon}
        alt={label}
        data-testid="MenuPoint-icon"
        width={width}
        height={height}
      />
    </button>
  );
};

export default MenuPointIconButton;
