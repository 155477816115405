import React from 'react';
import EditIcon from '../../assets/icons/EDITAR.svg';

const EditIconButton = ({ label, width = "20px", height = "20px"  }) => {
  return (
    <button aria-label={label} style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}>
      <img src={EditIcon} alt={label} data-testid="edit-icon" width={width} height={height}/>
    </button>
  );
};

export default EditIconButton;