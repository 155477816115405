import React from 'react';
import { BsFilter } from 'react-icons/bs';
import { FaChevronDown } from 'react-icons/fa'; 

const FilterButton = () => {
  return (
    <button
      style={{
        background: "transparent", // Fundo transparente
        border: "none", // Borda sutil
        color: "gray", // Cor padrão
        borderRadius: "8px", // Bordas levemente arredondadas
        padding: "5px 10px", // Espaçamento interno
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px", 
        cursor: "pointer",
        transition: "transform 0.2s",
      }}
      onClick={() => console.log('Abrir opções de filtro')}
      onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")} // Efeito de hover
      onMouseLeave={(e) => (e.target.style.transform = "scale(1)")} // Retorna ao tamanho normal
    >
      <BsFilter style={{ fontSize: "25px", color: "gray" }} />
      <FaChevronDown style={{ fontSize: "20px", color: "gray" }} />
    </button>
  );
};

export default FilterButton;