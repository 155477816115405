import React from "react";
import PerfilIcon from "../../assets/icons/PERFIL.svg";

const PerfilIconButton = ({ label, width = "20px", height = "20px" }) => {
  return (
    <button
      aria-label={label}
      style={{
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        margin: 0,
        cursor: "pointer",
      }}
    >
      <img
        src={PerfilIcon}
        alt={label}
        data-testid="perfil-icon"
        width={width}
        height={height}
      />
    </button>
  );
};

export default PerfilIconButton;