import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const MapReportAreaButton = ({
  enabled,
  enableFunction,
  tooltipText,
  tooltipPlacement = "bottom",
  enabledImage,
  disabledImage,
  tooltipId,
}) => {
  return (
    <div>
      <div className="m-0 mb-auto item-menu">
        <div
          onClick={() => {
            if (enabled) enableFunction(false);
            else enableFunction(true);
          }}
        >
          <h3
            className="text-black-50 m-0 text-center icon-menu"
            id={tooltipId}
          >
            {enabled ? enabledImage : disabledImage}
          </h3>

          <UncontrolledTooltip placement={tooltipPlacement} target={tooltipId}>
            {tooltipText}
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

export default MapReportAreaButton;
