import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import { FaEraser, FaSave, FaDrawPolygon } from "react-icons/fa";
import "./ModalDragMapPoints.css";

const ModalDragMapPoints = ({
  clearAll,
  saveUpdatedMarkers,
  startDrawingPolygon,
  offCanvas,
  polygonsNum
}) => {
  return (
    <Card
      className="modal-drag-map-points"
      style={{
        right: offCanvas ? "500px" : "125px",
        top: "65px",
        position: "absolute",
        zIndex: "1000",
        display: "block",
        overflowX: "scroll",
        width: "80px",
      }}
    >
      <CardBody
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Button
          color="primary"
          disabled={polygonsNum === 1}
          onClick={() => startDrawingPolygon()}
        >
          <FaDrawPolygon />
        </Button>
        <Button color="primary" onClick={clearAll}>
          <FaEraser />
        </Button>
        <Button color="success" onClick={saveUpdatedMarkers}>
          <FaSave />
        </Button>
      </CardBody>
    </Card>
  );
};

export default ModalDragMapPoints;
