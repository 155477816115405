import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Header from "../../elements/Header";
import GetTokenApi from "../KeyCloak/GetTokenApi";
import { BsArrowLeft } from "react-icons/bs";
import EditIconButton from "../../elements/EditIcon/EditIcon";
import MenuPointIconButton from "../../elements/MenuPointIcon/MenuPointIcon"; 

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const [userPermission, setUserPermission] = useState(null);
  const [permissionsData, setPermissionsData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Obtendo os dados do usuário
  useEffect(() => {
    const fetchUserPermission = async () => {
      const userData = await GetTokenApi();
      setUserPermission(userData);
    };
    fetchUserPermission();
  }, []);

  // Obtendo o mapeamento de permissões
  useEffect(() => {
    const fetchPermissionsData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(`${apiUrl}/users/roles`, {
          method: "GET",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${userPermission?.access_token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPermissionsData(data);
        } else {
          throw new Error("Erro ao carregar permissões.");
        }
      } catch (error) {
        console.error("Erro ao buscar permissões:", error);
      }
    };

    if (userPermission) {
      fetchPermissionsData();
    }
  }, [userPermission]);

  // Encontrando o nome da permissão do usuário
  const userRoleName = userPermission
    ? permissionsData.find(
        (permission) => permission.role === userPermission.role
      )?.name
    : "-";

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div>
      <Header />
      {/* Adicionando espaço entre o Header e o restante */}
      <div style={{ marginTop: "10vh" }}>
        <div className="container mt-5">
          <div
            className="p-4"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Button
                  color="link"
                  className="p-0"
                  onClick={() => navigate(-1)}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <BsArrowLeft size={20} />
                </Button>
                <h3 className="ms-2 mb-0" style={{ color: "#000" }}><strong>Meu perfil</strong></h3>
              </div>
            </div>
            {/* Linha Horizontal abaixo do título */}
            <hr />
            {userPermission ? (
              <div
                className="p-4"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
                  marginTop: "20px",
                  border: "1px solid #3c3f49",
                  position: "relative",
                  marginBottom: "20px",
                }}
              >
               <Dropdown
    isOpen={dropdownOpen}
    toggle={toggleDropdown}
    className="dropdown-container"
    direction="down"
    style={{
      position: "absolute", // Posiciona o dropdown
      top: "10px", // Distância do topo do contêiner
      right: "10px", // Distância do lado direito do contêiner
      zIndex: "1050", // Garante que fique acima de outros elementos
    }}
  >
    <DropdownToggle tag="div" style={{ cursor: "pointer" }}>
      <div
        style={{
          width: "30px",
          height: "30px",
          backgroundColor: "transparent",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MenuPointIconButton width="40px" height="40px"/>
      </div>
    </DropdownToggle>
    <DropdownMenu
      end // Alinha o menu à direita
      style={{
        zIndex: "1050",
      }}
    >
      {userPermission && (
        <DropdownItem tag={Link} to={`/editarusuario/${userPermission.id}`}>
          <EditIconButton />
          <span className="ms-2">Editar dados</span>
        </DropdownItem>
      )}
    </DropdownMenu>
  </Dropdown>

                <Row>
                  <Col sm={6} className="mb-4">
                  <span style={{ color: "#000" }}>Nome</span>
                  <br />
                    <span className="mb-1 text-muted">{userPermission.first_name}</span>
                  </Col>
                  <Col sm={6} className="mb-4">
                  <span style={{ color: "#000" }}>Sobrenome</span>
                  <br />
                  <span className="mb-1 text-muted">{userPermission.last_name}</span>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <span style={{ color: "#000" }}>Nome de Usuário</span>
                    <br />
                    <span className="mb-1 text-muted">{userPermission.username}</span>
                  </Col>
                  <hr />
                  <Col sm={6} className="mb-4">
                    <span style={{ color: "#000" }}>E-mail</span>
                    <br />
                    <span className="mb-1 text-muted">{userPermission.email}</span>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <span style={{ color: "#000" }}>Telefone</span>
                    <br />
                    <span className="mb-1 text-muted">{userPermission.phone || "-"}</span>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <span style={{ color: "#000" }}>Senha</span>
                    <br />
                    <span className="mb-1 text-muted">{userPermission.password || "*******************"}</span>
                  </Col>
                  <Col sm={6} className="mb-4">
                    <span style={{ color: "#000" }}>Permissão</span>
                    <br />
                    <span className="mb-1 text-muted">{userRoleName}</span>
                  </Col>
                  
                </Row>
              </div>
            ) : (
              <p>Carregando dados...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsPage;
